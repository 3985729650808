import { useMemo } from "react"
import { X } from "lucide-react"
import { extractComments } from "@/pages/crFileChanges/components/CRHeader/ReviewersSection/utils.tsx"
import { Dialog, DialogContent, DialogTitle, DialogTrigger } from "@/components"
import { Comment } from "@/components/designSystem/Discussions/components/Comment"
import { AddComment } from "@/components/designSystem/Discussions/components/Comment/AddComment.tsx"
import { useTranslation } from "react-i18next"
import { BaseDialogProps } from "@/pages/crFileChanges/components/CRHeader/CRActions/InfoActions/types"
import { ChangeRequest } from "@/models/Changes"

export const CRCommentsDialog = ({
  onClose,
  onTriggerClick,
  open,
  children,
  changeRequest
}: BaseDialogProps & { changeRequest: ChangeRequest }) => {
  const comments = useMemo(() => extractComments(changeRequest.change_level_discussions), [changeRequest])
  const lastDiscussion = changeRequest.change_level_discussions.slice(-1)[0]
  const { t } = useTranslation("FCC")

  return (
    <Dialog open={open} modal={false}>
      <DialogTrigger onClick={onTriggerClick}>{children}</DialogTrigger>

      <DialogContent
        onInteractOutside={onClose}
        onEscapeKeyDown={onClose}
        className="flex h-fit max-h-[399px] max-w-[696px] flex-col overflow-hidden rounded-2xl border border-border p-8 text-primary"
      >
        <DialogTitle className="mb-1 flex w-full items-center justify-between">
          <p className="h-8 content-center px-2 py-1 text-xs text-primary">
            {comments.length} {comments.length === 1 ? t("CRComment") : t("CRComments")}
          </p>
          <X onClick={onClose} width={16} height={16} className="cursor-pointer" />
        </DialogTitle>

        <div className="relative flex flex-col items-center overflow-y-auto bg-background text-xs">
          {comments.map((comment, index) => (
            <Comment comment={comment} key={comment.id} current={null} commentIndex={index} isResolved={false} />
          ))}
          <AddComment discussion={lastDiscussion} crId={changeRequest.id} commitSha={changeRequest.commits[0]?.sha} />
        </div>
      </DialogContent>
    </Dialog>
  )
}
