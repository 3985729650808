import { ExternalLink, SkeletonLoader } from "@/components"
import { ExternalLink as ExternalLinkIcon } from "lucide-react"
import { useSearchParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useFetchGithubIntegrations } from "@/hooks/queries/useFetchGithubIntegrations"
import { RepositoriesContainer } from "./RepositoriesContainer"
import { errorHandler } from "@/services/api/helpers"
import { githubUrl } from "@/models/GithubIntegrations"

export const Github = () => {
  const [searchParams] = useSearchParams()
  const page = searchParams.get("page")
  const { t } = useTranslation("Integrations")
  const { data, error, isLoading } = useFetchGithubIntegrations({ page })
  document.title = "Settings - GitHub Integrations"

  if (isLoading) {
    return (
      <div>
        <SkeletonLoader />
      </div>
    )
  }

  if (error) {
    return (
      <div>
        <p>{errorHandler(error)}</p>
      </div>
    )
  }

  if (data && data.installations?.length > 0) {
    return <RepositoriesContainer data={data} githubUrl={githubUrl} />
  }

  return (
    <div data-testid="github-integration-description">
      <p className="mb-4 text-secondary">{t("gh_description.first")}</p>
      <p className="mb-4 text-secondary">{t("gh_description.second")}</p>

      <ExternalLink href={githubUrl} label={t("Configure")} Icon={ExternalLinkIcon} />
    </div>
  )
}
