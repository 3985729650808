import { apiRoutes } from "@/services/api/api-routes"
import { axiosClient } from "@/services/api/axiosClient"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useCallback } from "react"
import { BaseIntegrationPayload } from "@/models/Integration.ts"

export const useCreateIntegration = () => {
  const queryClient = useQueryClient()

  const createIntegration = useCallback(
    async (options: BaseIntegrationPayload) => await axiosClient.post(apiRoutes.integrations.post, options),
    []
  )

  const { data, mutateAsync, status, error } = useMutation({
    mutationKey: ["createIntegration"],
    mutationFn: createIntegration,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["integrations"] })
    },
    onError: (error) => {
      console.error("Failed to create Datadog integration:", error)
    }
  })

  return { data, mutateAsync, status, error }
}
