import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from "@/components"
import { MoreHorizontal } from "lucide-react"
import { useTranslation } from "react-i18next"
import { AcceptanceStats, FindingConfig } from "@/models/ReviewerConfig"
import { ReviewerType } from "@/pages/settings/reviewer/ReviewerTypes"
import { CircularGraph } from "@/components/ui/CircularGraph"

interface Props {
  handleClick: (item: FindingConfig) => void
  item: ReviewerType & { enabled: boolean }
  disabled: boolean
  stats?: AcceptanceStats
}

export const ConfigItem = ({ item, disabled, handleClick, stats }: Props) => {
  const { t } = useTranslation("Reviewer")

  return (
    <div
      className={`w-[calc(50%-8px)] rounded-md border border-border p-4 ${item.isConfigurable && !disabled ? "" : "bg-fill"}`}
    >
      <div className="flex flex-row">
        <div
          className={`flex size-8 items-center justify-center rounded bg-link ${item.isConfigurable && !disabled ? "" : "opacity-65"}`}
        >
          <item.icon className="size-4 text-white" />
        </div>
        <div className="ml-4 flex-col">
          <h5 className="mb-2 text-s font-medium text-primary">{item.display}</h5>
          <div className="text-xs font-normal text-secondary">{item.description}</div>
          {item.isConfigurable &&
            stats &&
            (item.enabled ? (
              <div className="mt-2 flex">
                <p className="text-s font-medium text-primary">
                  {stats.addressedOrgCounter} {t("Accepted")}
                </p>
                <CircularGraph
                  size={20}
                  fillColor="var(--blue-link)"
                  strokeWidth={2}
                  total={100}
                  current={stats.orgRate}
                  className="mx-2"
                  bgColor="var(--graph-bg)"
                />
                <p className="text-s text-secondary">
                  {stats.orgRate}% {t("Accepted")}
                </p>
              </div>
            ) : (
              <div className="mt-2 flex">
                <CircularGraph
                  size={20}
                  fillColor="grey"
                  strokeWidth={2}
                  total={100}
                  current={stats.orgRate}
                  className="mx-2"
                  bgColor="var(--graph-bg)"
                />
                <p className="text-s font-medium text-hint">
                  {stats.globalRate}% {t("PotentialAcceptanceRate")}
                </p>
              </div>
            ))}
        </div>
        {item.enabled ? (
          <div className="ml-auto flex justify-between">
            {item.isConfigurable ? (
              <div className="ml-2 px-2.5">
                <TooltipProvider delayDuration={0}>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div>
                        <DropdownMenu modal={false} open={!disabled ? undefined : false}>
                          <DropdownMenuTrigger
                            className={`p-1 ${disabled ? "cursor-not-allowed opacity-50" : ""}`}
                            disabled={disabled}
                          >
                            <MoreHorizontal className="size-4 hover:text-secondary" />
                          </DropdownMenuTrigger>
                          <DropdownMenuContent align="end">
                            <DropdownMenuItem
                              onClick={() => handleClick(item)}
                              className="flex items-center gap-2 text-error-dark"
                            >
                              {t("Deactivate")}
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </div>
                    </TooltipTrigger>
                    {disabled && (
                      <TooltipContent align="end" className="-translate-x-1 text-xs">
                        <p>{t("ConfigurationRestriction")}</p>
                      </TooltipContent>
                    )}
                  </Tooltip>
                </TooltipProvider>
              </div>
            ) : null}
          </div>
        ) : (
          <TooltipProvider delayDuration={0}>
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="ml-auto">
                  <Button
                    disabled={disabled}
                    variant="outline"
                    className="h-[32px] px-2.5 text-xs font-normal"
                    onClick={() => handleClick(item)}
                  >
                    {t("Activate")}
                  </Button>
                </span>
              </TooltipTrigger>
              {disabled && (
                <TooltipContent align="end" className="text-xs" sideOffset={5}>
                  <p>{t("ConfigurationRestriction")}</p>
                </TooltipContent>
              )}
            </Tooltip>
          </TooltipProvider>
        )}
      </div>
    </div>
  )
}
