import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { CommentProps } from "@/components/designSystem/Discussions/components/Comment/index.tsx"
import { isWordTooLong } from "./utils"
import { parseAndSanitizeComment } from "@/lib/utils"
import { shouldShowSeeMoreLess, splitCommentAndSuggestion } from "@/components/designSystem/Discussions/utils"
import { MdTransformer } from "@/lib/mdTransformer"
import { useSettings } from "@/services/store/useSettings"
import { Button } from "@/components"
import { useDiscussions } from "@/services/store/useDiscussions"

export const RenderComment = ({ comment, commentIndex, isResolved, isSuggestion = false }: CommentProps) => {
  const { comment: commentText } = splitCommentAndSuggestion(comment.comment_body)
  const { selectedTheme } = useSettings()
  const [isSeeMoreExpanded, setIsSeeMoreExpanded] = useState(false)
  const { t } = useTranslation("Common")
  const { toggleManualRecalculationTrigger } = useDiscussions()
  const contentType = useMemo(
    () => (comment.body_content_type === "html" ? "html" : "markdown"),
    [comment.body_content_type]
  )

  const showSeeMoreAndLess = useMemo(
    () => commentText && !isSuggestion && shouldShowSeeMoreLess(commentIndex, isResolved, commentText, contentType),
    [isSuggestion, commentIndex, isResolved, commentText, contentType]
  )

  const isMainCommentCollapsed = !isSeeMoreExpanded && showSeeMoreAndLess

  if (!commentText) return null

  return (
    <div className="w-full text-primary">
      <div
        className={`${isWordTooLong(commentText) ? "break-all" : "break-words"} text-xs ${
          isMainCommentCollapsed ? "line-clamp-1" : ""
        }`}
        tabIndex={0}
        aria-label="comment with rich editor"
      >
        {comment.body_content_type === "html" ? (
          <div
            className="whitespace-pre-wrap"
            dangerouslySetInnerHTML={{ __html: parseAndSanitizeComment(commentText) }}
          ></div>
        ) : (
          MdTransformer(commentText, selectedTheme)
        )}
      </div>

      {showSeeMoreAndLess && (
        <div>
          {isMainCommentCollapsed ? (
            <Button
              variant="link"
              onClick={() => {
                setIsSeeMoreExpanded(true)
                toggleManualRecalculationTrigger()
              }}
              className="h-5 p-0"
            >
              <p className="text-xs font-semibold text-link">{t("SeeMore")}</p>
            </Button>
          ) : (
            <Button
              variant="link"
              onClick={() => {
                setIsSeeMoreExpanded(false)
                toggleManualRecalculationTrigger()
              }}
              className="h-5 p-0"
            >
              <p className="text-xs font-semibold text-link">{t("SeeLess")}</p>
            </Button>
          )}
        </div>
      )}
    </div>
  )
}
