import {
  BookType,
  GitPullRequestClosed,
  LucideProps,
  MessageCircleQuestion,
  ShieldHalf,
  ShowerHead,
  Siren,
  SquareBottomDashedScissors,
  SquareDashedBottomCode,
  WholeWord
} from "lucide-react"
import { FindingType } from "@/models/ReviewerConfig.ts"
import { ForwardRefExoticComponent, RefAttributes } from "react"

export const REVIEWER_TYPES = [
  {
    display: "Breaking Changes",
    icon: Siren,
    description:
      "Changes that alter or remove existing functionality, potentially breaking dependent APIs or features.",
    isConfigurable: false,
    findingType: FindingType.BREAKING_CHANGE
  },
  {
    display: "CI Errors",
    icon: GitPullRequestClosed,
    description: "Highlighting failed checks and integration tests, and provides detailed log errors.",
    isConfigurable: false,
    findingType: FindingType.LOG_ERROR
  },
  {
    display: "Typos and Ambiguous Identifiers",
    icon: BookType,
    description: "Mistakes in variable names or unclear identifiers that reduce code clarity and readability.",
    isConfigurable: true,
    findingType: FindingType.NAMING_AND_TYPOS
  },
  {
    display: "Code Hygiene",
    icon: ShowerHead,
    description: "Ensuring code is clean, well-organized, and follows consistent style guidelines for maintainability.",
    isConfigurable: true,
    findingType: FindingType.CODE_HYGIENE
  },
  {
    display: "Type Correctness",
    icon: WholeWord,
    description: "Ensuring that variables and functions use the appropriate data types to prevent type-related errors.",
    isConfigurable: true,
    findingType: FindingType.TYPE_INCONSISTENCY
  },
  {
    display: "Concise Code",
    icon: SquareBottomDashedScissors,
    description:
      "Writing clear, efficient code that avoids unnecessary complexity or redundancy while maintaining readability.",
    isConfigurable: true,
    findingType: FindingType.CONCISENESS
  },
  {
    display: "Code Deduplication and Convention Enforcement",
    icon: SquareDashedBottomCode,
    description: "Detects duplicate code and ensures adherence to your team's existing coding patterns.",
    isConfigurable: true,
    findingType: FindingType.CODE_DEDUP_AND_CONVENTIONS
  },
  {
    display: "Assertion Coverage",
    icon: ShieldHalf,
    description: "Verifying sufficient test cases cover all possible assertions to ensure code behaves as expected.",
    isConfigurable: true,
    findingType: FindingType.TEST_QUALITY
  },
  {
    display: "Addressed/Unaddressed comments",
    icon: MessageCircleQuestion,
    description: "Tracking resolved comments and identifying remaining issues that still need attention.",
    isConfigurable: true,
    findingType: FindingType.ADDRESSED
  }
]

export type ReviewerType = {
  display: string
  icon: ForwardRefExoticComponent<Omit<LucideProps, "ref"> & RefAttributes<SVGSVGElement>>
  description: string
  isConfigurable: boolean
  findingType: FindingType
}
