import { Button } from "@/components"
import { useTranslation } from "react-i18next"
import CollapseAllSVG from "@/assets/icons/CollapseAllSVG"
import ExpandAllSVG from "@/assets/icons/ExpandAllSVG"

interface ExpandCollapseButtonProps {
  isExpanded: boolean
  hiddenCount: number
  onClick: () => void
}

export const ExpandCollapseButton = ({ isExpanded, hiddenCount, onClick }: ExpandCollapseButtonProps) => {
  const { t: tFCC } = useTranslation("FCC")

  if (hiddenCount <= 0) {
    return null
  }

  return (
    <Button variant="ghost" className="mt-2 flex h-4 w-full items-center gap-2 p-0" onClick={onClick}>
      {isExpanded ? (
        <>
          <CollapseAllSVG color="var(--base-hint)" />
          <div className="h-px flex-1 bg-hint" />
          <p className="text-xxs text-hint">
            Collapse {hiddenCount} {hiddenCount === 1 ? tFCC("Comment") : tFCC("Comments")}
          </p>
        </>
      ) : (
        <>
          <ExpandAllSVG color="var(--base-hint)" />
          <div className="h-px flex-1 bg-hint" />
          <p className="text-xxs text-hint">
            View {hiddenCount} more {hiddenCount === 1 ? tFCC("Comment") : tFCC("Comments")}
          </p>
        </>
      )}
    </Button>
  )
}
