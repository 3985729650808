import { useQuery } from "@tanstack/react-query"
import { axiosClient } from "@/services/api/axiosClient"
import { apiRoutes } from "@/services/api/api-routes"
import { AcceptanceRates } from "@/models/ReviewerConfig.ts"

export const useFetchAcceptanceRates = () => {
  const { error, isLoading, data } = useQuery<AcceptanceRates, unknown>({
    queryKey: ["acceptance-rates"],
    queryFn: async () => {
      const response = await axiosClient.get(apiRoutes.reviewerConfig.acceptanceRates.get)
      return response.data
    }
  })

  return { error, isLoading, data }
}
