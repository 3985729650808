import { useCallback, useEffect } from "react"
import { motion } from "framer-motion"
import { Committer, FileDetails } from "@/models/Topics"
import { CIRunWithErrors } from "@/models/Changes"
import { scrollToPosition } from "@/pages/crFileChanges/components/helpers"
import { TooltipProvider } from "@/components"
import { BreakingChangeDetails, UniqueStatEntry } from "@/components/designSystem/Insights/helpers"
import { CommitterItem } from "@/components/designSystem/Topics/CommitterItem"
import { StatItem } from "@/components/designSystem/Topics/StatItem"
import { BreakingChangeItem } from "@/components/designSystem/Topics/BreakingChangeItem"
import { ErrorAccordion } from "@/components/designSystem/Topics/ErrorAccordion"
import BreakingChangeContent from "@/components/designSystem/Topics/BreakingChangeContent"
import LogErrorContent from "@/components/designSystem/Topics/LogErrorContent"
import { CodeBox } from "@/components/designSystem/CodeBox"
import { LogErrorItem } from "@/components/designSystem/Topics/LogErrorItem.tsx"
import { useTranslation } from "react-i18next"
import { Link } from "@/components/designSystem/Link"
import { HEADER_HEIGHT, useChangeRequest } from "@/services/store/useChangeRequest"
import { useBazzy } from "@/services/store/useBazzy"

type InsightType = "usage" | "checks" | "developers" | "name" | "files" | "empty"

interface InsightDialogProps {
  topicDescription: string | null
  type: InsightType
  data: (UniqueStatEntry | BreakingChangeDetails | CIRunWithErrors | Committer | FileDetails)[] | []
  handleClose: (forceClose?: boolean) => void
}

function isUniqueStatEntry(item: UniqueStatEntry | BreakingChangeDetails): item is UniqueStatEntry {
  return "method" in item
}
function isCIRun(item: CIRunWithErrors | BreakingChangeDetails): item is CIRunWithErrors {
  return "ci_name" in item
}

function parseDescription(description: string) {
  const parts = description.split(/(`[^`]+`)/)
  return parts.map((part, index) => {
    if (part.startsWith("`") && part.endsWith("`")) {
      return <CodeBox key={index}>{part.slice(1, -1)}</CodeBox>
    }
    return part
  })
}

export function InsightsDialog({ topicDescription, type, data, handleClose }: InsightDialogProps) {
  const { t } = useTranslation("Insight")
  const { setRunErrorItemToBeShown, setBreakingChangeItemToBeShown } = useChangeRequest()
  const { setEnableFetch } = useBazzy()

  useEffect(() => {
    setEnableFetch(false)
  }, [setEnableFetch])

  const slideVariants = {
    initial: {
      x: -20,
      opacity: 0
    },
    animate: {
      x: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30
      }
    },
    exit: {
      x: 20,
      opacity: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30
      }
    }
  }

  const onFileClick = useCallback((path: string) => {
    const file = document.getElementById(path)
    if (file) {
      const elementTop = file.getBoundingClientRect().top
      scrollToPosition({ elementTop, offset: HEADER_HEIGHT })
    }
  }, [])

  const shouldShowHeader = type && type !== "name" && type !== "empty"

  const handleErrorItemClick = useCallback(
    (check: CIRunWithErrors | BreakingChangeDetails) => {
      isCIRun(check) ? setRunErrorItemToBeShown(check) : setBreakingChangeItemToBeShown(check)
    },
    [setRunErrorItemToBeShown, setBreakingChangeItemToBeShown]
  )

  const renderContent = () => {
    switch (type) {
      case "files":
        return (
          <motion.div
            className="mt-1 flex flex-col justify-start"
            initial={{ opacity: 0, y: 5 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1 }}
          >
            {(data as FileDetails[]).map((file, index) => (
              <Link
                key={index}
                onClick={() => {
                  onFileClick(file.path)
                  handleClose()
                }}
              >
                {file.path}
              </Link>
            ))}
          </motion.div>
        )
      case "developers":
        return (data as Committer[]).map((committer, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 5 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.05 }}
          >
            <CommitterItem committer={committer} />
          </motion.div>
        ))
      case "checks":
        return (data as CIRunWithErrors[]).map((check, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 5 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.05 }}
          >
            <ErrorAccordion
              onClick={handleErrorItemClick}
              check={check}
              TriggerElement={<LogErrorItem check={check} />}
              ContentElement={<LogErrorContent errorItem={check} onFileClick={onFileClick} handleClose={handleClose} />}
            />
          </motion.div>
        ))
      case "usage":
        return (data as (UniqueStatEntry | BreakingChangeDetails)[]).map((item, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 5 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.05 }}
          >
            {isUniqueStatEntry(item) ? (
              <StatItem insight={item} handleClose={handleClose} />
            ) : (
              <ErrorAccordion
                onClick={handleErrorItemClick}
                check={item}
                TriggerElement={<BreakingChangeItem breakingChange={item} />}
                ContentElement={
                  <BreakingChangeContent breakingItem={item} onFileClick={onFileClick} handleClose={handleClose} />
                }
              />
            )}
          </motion.div>
        ))
      default:
        return null
    }
  }

  return (
    <TooltipProvider>
      <motion.section
        variants={slideVariants}
        initial="initial"
        animate="animate"
        exit="exit"
        className="max-h-[542px] min-w-[350px] max-w-dialog overflow-y-scroll rounded-md border border-border bg-background p-4 text-xs text-primary dark:shadow-background"
      >
        {topicDescription && (
          <motion.p
            className="text-s font-medium leading-[22px] first-letter:capitalize"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.1 }}
          >
            {parseDescription(topicDescription)}
          </motion.p>
        )}
        {shouldShowHeader && (
          <motion.h2
            className="my-2 text-xxs leading-4 first-letter:capitalize"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.15 }}
          >
            {t(`${type}Header`)}:
          </motion.h2>
        )}
        {renderContent()}
      </motion.section>
    </TooltipProvider>
  )
}
