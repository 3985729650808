import { SkeletonLoader } from "@/components"
import { useFetchIntegrations } from "@/hooks/queries/useFetchIntegrations"
import { useTranslation } from "react-i18next"
import { errorHandler } from "@/services/api/helpers"
import { useEffect } from "react"
import { routes } from "@/lib/routes.ts"
import { useNavigate, useSearchParams } from "react-router-dom"
import { IntegrationCard } from "@/pages/settings/integrations/slack/IntegrationCard.tsx"
import { SlackIntegration } from "@/models/Integration.ts"
import { useFetchOAuthState } from "@/hooks/queries/useFetchOAuthState.tsx"
import { runtimeConfig } from "@/lib/config.ts"
import { Footer } from "@/pages/settings/integrations/slack/Footer.tsx"

const AddToSlackButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <img
      alt="Add to Slack"
      height="40"
      width="139"
      src="https://platform.slack-edge.com/img/add_to_slack.png"
      srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
      onClick={onClick}
      className="cursor-pointer"
    />
  )
}

export const Slack = () => {
  const { t } = useTranslation("Integrations")
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { error, isLoading, data } = useFetchIntegrations("slack")
  const { data: oauthState } = useFetchOAuthState("slack")
  document.title = "Settings - Slack Integrations"

  const code = searchParams.get("code")

  useEffect(() => {
    if (code) {
      navigate({
        pathname: routes.settings.slackCallback,
        search: `?code=${code}`
      })
    }
  }, [code, navigate])

  const onClickAddToSlack = () => {
    const url = `https://slack.com/oauth/v2/authorize?client_id=${runtimeConfig.VITE_SLACK_CLIENT_ID}&state=${oauthState?.state}&scope=channels:join,chat:write,im:write,users:read,users:read.email&user_scope=`
    window.open(url, "_blank", "noopener,noreferrer")
  }

  if (isLoading) {
    return (
      <div>
        <SkeletonLoader />
      </div>
    )
  }

  if (error) {
    return (
      <div>
        <p>{errorHandler(error)}</p>
      </div>
    )
  }

  if (data && data.integrations?.length > 0) {
    return (
      <div className="flex flex-col gap-2">
        <div className="flex items-center justify-between">
          <h1 className="text-sm font-medium text-secondary">{t("Slack")}</h1>
          <AddToSlackButton onClick={() => onClickAddToSlack()} />
        </div>
        <div className="grid w-full grid-cols-2 gap-4">
          {data.integrations.map((integration) => (
            <IntegrationCard key={integration.id} integration={integration as SlackIntegration} />
          ))}
        </div>
        <Footer />
      </div>
    )
  }

  return (
    <div>
      <div data-testid="slack-integration-description" className="text-primary">
        <p className="mb-4">{t("slack_description.first")}</p>
        <p className="mb-4">{t("slack_description.second")}</p>
        <AddToSlackButton onClick={() => onClickAddToSlack()} />
      </div>
      <Footer />
    </div>
  )
}
