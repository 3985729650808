import { Comment as CommentI, Discussion } from "@/models/Discussions"
import { useTranslation } from "react-i18next"
import { useDiscussions } from "@/services/store/useDiscussions"
import { useCallback } from "react"
import { useToast } from "@/hooks/useToast"
import { DiscussionHeader } from "./DiscussionHeader"
import { ResolvedDiscussionView } from "./ResolvedDiscussionView"
import { UnresolvedDiscussionView } from "./UnresolvedDiscussionView"

interface Props {
  comments: CommentI[]
  discussion: Discussion
  handleResolveToggle: (resolvedState: boolean) => void
  isResolved: boolean
  id: string
  areAllCommentsExpanded: boolean
  setAreAllCommentsExpanded: (value: boolean) => void
}

export const DiscussionBody = ({
  comments,
  discussion,
  handleResolveToggle,
  isResolved,
  id,
  areAllCommentsExpanded,
  setAreAllCommentsExpanded
}: Props) => {
  const { t: commonTranslation } = useTranslation("Common")
  const { clearCurrentHoveredDiscussion, setCurrentHoveredDiscussion } = useDiscussions()
  const { toast } = useToast()

  const onCopyClick = useCallback(() => {
    toast({
      title: commonTranslation("CopiedLink")
    })
  }, [commonTranslation, toast])

  const commonProps = {
    comments,
    discussion,
    handleResolveToggle,
    isResolved,
    areAllCommentsExpanded,
    setAreAllCommentsExpanded
  }

  return (
    <div
      className="text-left"
      onMouseEnter={() => setCurrentHoveredDiscussion(discussion)}
      onMouseLeave={() => clearCurrentHoveredDiscussion()}
    >
      <DiscussionHeader
        discussion={discussion}
        handleResolveToggle={handleResolveToggle}
        isResolved={isResolved}
        id={id}
        onCopyClick={onCopyClick}
      />

      {isResolved ? <ResolvedDiscussionView {...commonProps} /> : <UnresolvedDiscussionView {...commonProps} />}
    </div>
  )
}
