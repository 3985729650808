import { create } from "zustand"
import { immer } from "zustand/middleware/immer"
import { ChangeRequest, CIRunWithErrors } from "@/models/Changes"
import { enableMapSet } from "immer"
import { Tables } from "@/models/FCC"
import { BreakingChangeItem } from "@/models/Insight"
import { Topic } from "@/models/Topics"

enableMapSet()

// Calculated by the top nav bar (64px) and the cr header and topics height (100px)
export const HEADER_HEIGHT = 164

type StoreState = {
  headerHeight: number
  runErrorItemToBeShown: CIRunWithErrors | null
  breakingChangeItemToBeShown: BreakingChangeItem | null
  changeRequest: ChangeRequest | null
  tables: Tables
  topics: Topic[]
  selectedTopic: Topic | null
  isDescriptionOpen: boolean
  flowChartData: string | null
  isCRCommentsDialogOpen: boolean
  userClosedManually: boolean
}

type StoreActions = {
  setTopics: (topics: Topic[]) => void
  setSelectedTopic: (topic: Topic | null) => void
  setChangeRequest: (pr: ChangeRequest) => void
  setTables: (newTables: Tables) => void
  setRunErrorItemToBeShown: (runErrorItem: CIRunWithErrors | null) => void
  setBreakingChangeItemToBeShown: (breakingChangeItem: BreakingChangeItem | null) => void
  updateTopicViewStatus: (topicId: string, isViewed: boolean) => void
  incrementHeaderHeight: (height: number) => void
  resetHeaderHeight: () => void
  setFlowChartData: (chartData: string | null) => void
  setIsCRCommentsDialogOpen: (isCRCommentsDialogOpen: boolean) => void
  setIsDescriptionOpen: (isOpen: boolean) => void
  setUserClosedManually: (userClosedManually: boolean) => void
}

type Store = StoreState & StoreActions

const initialState: StoreState = {
  headerHeight: HEADER_HEIGHT,
  runErrorItemToBeShown: null,
  breakingChangeItemToBeShown: null,
  changeRequest: null,
  tables: {},
  topics: [],
  selectedTopic: null,
  isDescriptionOpen: true,
  flowChartData: null,
  isCRCommentsDialogOpen: false,
  userClosedManually: false
}

export const useChangeRequest = create<Store>()(
  immer((set) => ({
    ...initialState,

    setTopics: (topics) =>
      set((state) => {
        state.topics = topics
      }),

    setSelectedTopic: (topic) =>
      set((state) => {
        state.selectedTopic = topic
      }),

    setChangeRequest: (newChange) =>
      set((state) => {
        state.changeRequest = newChange
      }),

    setTables: (newTables) =>
      set((state) => {
        state.tables = { ...state.tables, ...newTables }
      }),

    setRunErrorItemToBeShown: (runErrorItem) =>
      set((state) => {
        state.runErrorItemToBeShown = runErrorItem
      }),

    setBreakingChangeItemToBeShown: (breakingChangeItem) =>
      set((state) => {
        state.breakingChangeItemToBeShown = breakingChangeItem
      }),

    updateTopicViewStatus: (topicId, isViewed) =>
      set((state) => {
        state.topics = state.topics.map((topic) => (topic.id === topicId ? { ...topic, isViewed } : topic))
        if (state.selectedTopic?.id === topicId) {
          state.selectedTopic = { ...state.selectedTopic, isViewed }
        }
      }),

    incrementHeaderHeight: (height: number) =>
      set((state) => {
        state.headerHeight = state.headerHeight + height
      }),

    resetHeaderHeight: () =>
      set((state) => {
        state.headerHeight = HEADER_HEIGHT
      }),

    setFlowChartData: (chartData: string | null) =>
      set((state) => {
        state.flowChartData = chartData
      }),

    setIsCRCommentsDialogOpen: (isCRCommentsDialogOpen: boolean) => set({ isCRCommentsDialogOpen }),

    setIsDescriptionOpen: (isDescriptionOpen: boolean) => set({ isDescriptionOpen }),

    setUserClosedManually: (userClosedManually: boolean) => set({ userClosedManually })
  }))
)
