import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { tBodyScrollOffset } from "@/pages/crFileChanges/components/helpers"
import { HEADER_HEIGHT } from "@/services/store/useChangeRequest"

interface ScrollToAnchorProps {
  sideEffectCallback?: (hash: string) => void
}

export function ScrollToAnchor({ sideEffectCallback }: ScrollToAnchorProps) {
  const location = useLocation()

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!location.hash) {
        return
      }
      const hash = location.hash.slice(1)

      if (hash && document.getElementById(hash)) {
        const elementTop = document.getElementById(hash)?.getBoundingClientRect().top || 0
        scrollTo({ top: elementTop - tBodyScrollOffset - HEADER_HEIGHT, behavior: "instant" })
      }

      sideEffectCallback && sideEffectCallback(hash)
    }, 1000)

    return () => clearTimeout(timeout)
  }, [location, sideEffectCallback])

  return null
}
