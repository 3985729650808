import { Comment as CommentI, Discussion } from "@/models/Discussions"
import { CommentRenderer } from "./CommentRenderer"
import { ExpandCollapseButton } from "./ExpandCollapseButton"
import { useDiscussions } from "@/services/store/useDiscussions"

interface ResolvedDiscussionViewProps {
  comments: CommentI[]
  discussion: Discussion
  handleResolveToggle: (resolvedState: boolean) => void
  isResolved: boolean
  areAllCommentsExpanded: boolean
  setAreAllCommentsExpanded: (value: boolean) => void
}

export const ResolvedDiscussionView = ({
  comments,
  discussion,
  handleResolveToggle,
  isResolved,
  areAllCommentsExpanded,
  setAreAllCommentsExpanded
}: ResolvedDiscussionViewProps) => {
  const { toggleManualRecalculationTrigger } = useDiscussions()
  const shouldShowButton = comments.length > 1
  const hiddenCount = comments.length - 1

  return (
    <>
      {comments.length > 0 && (
        <CommentRenderer
          comment={comments[0]}
          index={0}
          discussion={discussion}
          handleResolveToggle={handleResolveToggle}
          isResolved={isResolved}
        />
      )}

      {areAllCommentsExpanded &&
        comments
          .slice(1)
          .map((comment, idx) => (
            <CommentRenderer
              key={comment.id}
              comment={comment}
              index={idx + 1}
              discussion={discussion}
              handleResolveToggle={handleResolveToggle}
              isResolved={isResolved}
            />
          ))}

      {shouldShowButton && (
        <ExpandCollapseButton
          isExpanded={areAllCommentsExpanded}
          hiddenCount={hiddenCount}
          onClick={() => {
            setAreAllCommentsExpanded(!areAllCommentsExpanded)
            toggleManualRecalculationTrigger()
          }}
        />
      )}
    </>
  )
}
