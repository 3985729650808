export const FindingType = {
  NAMING_AND_TYPOS: "naming_and_typos",
  CODE_HYGIENE: "code_hygiene",
  TEST_QUALITY: "test_quality",
  TYPE_INCONSISTENCY: "type_inconsistency",
  CONCISENESS: "conciseness",
  BREAKING_CHANGE: "breaking_change",
  LOG_ERROR: "log_error",
  CODE_DEDUP_AND_CONVENTIONS: "code_dedup_and_conventions",
  ADDRESSED: "addressed"
} as const

export type FindingType = (typeof FindingType)[keyof typeof FindingType]

export interface FindingConfig {
  findingType: FindingType
  enabled: boolean
}

export interface ExcludedConfig {
  fullRepoName: string
  excludedPaths: string
}

export interface ReviewerConfig {
  findingConfig: FindingConfig[]
  excludedConfig: ExcludedConfig[]
}

export interface AcceptanceRates {
  acceptanceRates: Record<FindingType, AcceptanceStats>
}

export interface AcceptanceStats {
  globalRate: number
  orgRate: number
  addressedOrgCounter: number
  orgCounter: number
}
