import { TooltipProvider } from "@radix-ui/react-tooltip"
import { NodeComponent } from "./NodeComponent"
import { useTranslation } from "react-i18next"
import { X } from "lucide-react"
import { FileWithDiffs } from "@/models/FCC"
import { useCallback, useMemo, useState } from "react"
import { buildTreeFromFilePaths } from "@/pages/crFileChanges/utils"
import { scrollToPosition } from "@/pages/crFileChanges/components/helpers"
import { Icon } from "@/models/Common"
import { HEADER_HEIGHT } from "@/services/store/useChangeRequest"

interface Props {
  onClose: () => void
  files: FileWithDiffs[]
  onFileClick?: () => void
  Icon?: Icon
}

export const DirectoryTree = ({ onClose, files, onFileClick, Icon }: Props) => {
  const { t } = useTranslation("FCC")
  const [selectedPath, setSelectedPath] = useState<string | null>(null)

  const computedTree = useMemo(() => buildTreeFromFilePaths(files), [files])

  const onFileClickWrapper = useCallback(
    (path: string) => {
      setSelectedPath(path)
      const file = document.getElementById(path)
      const elementTop = file?.getBoundingClientRect().top

      scrollToPosition({ elementTop, offset: HEADER_HEIGHT })
      if (typeof onFileClick === "function") {
        onFileClick()
      }
    },
    [onFileClick, setSelectedPath]
  )

  return (
    <div>
      <div className="mb-4 flex w-full items-center justify-between">
        <span className="select-none text-sm font-medium">{t("ChangedFiles")}</span>
        {Icon ? (
          <Icon onClick={onClose} width={16} height={16} className="cursor-pointer" />
        ) : (
          <X onClick={onClose} width={16} height={16} className="cursor-pointer" />
        )}
      </div>

      <TooltipProvider>
        {computedTree.map((node, index) => (
          <NodeComponent
            node={node}
            key={node.name + index}
            onToggle={onClose}
            onClick={onFileClickWrapper}
            selectedPath={selectedPath}
          />
        ))}
      </TooltipProvider>
    </div>
  )
}
