export const Badges = {
  Assignee: "Assignee",
  Draft: "draft"
}

export const Common = {
  Public: "Public",
  Private: "Private",
  Orgs: "Organizations",
  NoDataFound: "No data found...",
  WantsToMerge: "wants to merge",
  Into: "into",
  Commit: "commit",
  Commits: "commits",
  CreatedOn: "Created on",
  From: "from",
  Copied: "Copied!",
  Loading: "Loading..",
  By: "by",
  Save: "Save",
  Cancel: "Cancel",
  Submitting: "Submitting...",
  Submit: "Submit",
  Updated: "Updated",
  Files: (num: number) => `${num} file${num === 1 ? "" : "s"}`,
  NoTooltipContent: "No tooltip content provided",
  Done: "Done",
  CopiedLink: "Copied link",
  SeeMore: "See more",
  SeeLess: "See less"
}

export const Filters = {
  ClearFilters: "Clear Filters",
  NoResults: "No results found.",
  Search: "Search..",
  Selected: "Selected",
  Reset: "Reset",
  Apply: "Apply"
}

export const CommitHistory = {
  CommittedOn: "Committed on",
  CommitHistory: "Commit History"
}

export const WriteAccessDialog = {
  Cancel: "Cancel",
  Continue: "Continue",
  SuccessfullyGranted: "Success: Write access granted.",
  ErrorInPermission: "Error: Failed to grant write access. Try again.",
  AuthorizeAccountOnGithub: "Authorize your account on GitHub",
  AuthorizeOrgOnGithub: "Authorize Baz on GitHub",
  WritePermissionsRequired: "Write Permissions Required"
}

export const ConnectSlack = {
  Error: "Error: Failed to connect Slack. Try again.",
  Success: "Success: Slack connected successfully.",
  Action: "Enable Slack integration to follow your pull requests and get real-time notifications",
  BazUtilizes:
    "Baz utilizes the Slack integration solely to notify users on updates to their CRs, comments, CI and other events\n" +
    "        that occur within Baz.",
  BazPermissions:
    "During the installation process, Baz requests access to user details including emails, to be able to match\n" +
    "        others in your workspace with their existing Baz user."
}

export const Subscription = {
  Upgrade: "Upgrade Subscription",
  Manage: "Manage",
  Payment: "Payment & Billing",
  RemainingDays: "Remaining days for trial",
  AccountSettings: "Account Settings",
  NumberOfContributors: "Number of contributors",
  Account: "Account",
  UpgradeAction: "Upgrade your team's account to keep building unbreakable things together",
  Upgraded: "Upgraded Subscription",
  Delete: "Delete my account",
  AreYouSure: "Are you sure you want to delete your account?",
  Confirmation:
    "By confirming, your account will be permanently deleted. All team members will lose access immediately, and Baz will stop reviewing your Change Requests.",
  Cancel: "Cancel",
  Ok: "Yes, delete account"
}

export const Reviewer = {
  ReviewerWorkflow: "Customize Reviewer workflows",
  Activate: "Activate",
  Deactivate: "Deactivate",
  ConfigurationRestriction: "Configuration restricted to admins",
  ExcludedPaths: "Excluded Paths",
  ExcludedPathsDescription:
    "Specify which paths you would like to exclude from Reviewer's scope. Define wildcards using asterisks.",
  PotentialAcceptanceRate: "Potential acceptance rate",
  AcceptedSuggestions: "Accepted Suggestions",
  OverallAccepted: "Overall Accepted",
  Accepted: "Accepted",
  AcceptedExplanation: "A comment is accepted if a later diff fixes, removes, or mitigates the issue."
}
