import { useQuery } from "@tanstack/react-query"
import { axiosClient } from "@/services/api/axiosClient"
import { apiRoutes } from "@/services/api/api-routes"
import { Integrations } from "@/models/Integration.ts"

export const useFetchIntegrations = (integrationType: string) => {
  const { error, isLoading, data } = useQuery<Integrations, unknown>({
    queryKey: ["integrations", integrationType],
    queryFn: async () => {
      const response = await axiosClient.get(apiRoutes.integrations.get, {
        params: { integrationType }
      })

      return response.data
    }
  })

  return { error, isLoading, data }
}
