import { useTranslation } from "react-i18next"
import { useMemo } from "react"
import { SpinnerLoader } from "@/components/designSystem/Loaders/SpinnerLoader.tsx"
import { useFetchUser } from "@/hooks/queries/useFetchUser.tsx"
import { useFetchReviewerConfig } from "@/hooks/queries/useFetchReviewerConfig.tsx"
import { REVIEWER_TYPES } from "@/pages/settings/reviewer/ReviewerTypes.tsx"

import { AlertCircle, Info } from "lucide-react"
import { useUpdateReviewerConfiguration } from "@/hooks/mutations/useUpdateReviewerConfiguration"
import { ExcludedConfig, FindingConfig } from "@/models/ReviewerConfig.ts"
import { ConfigItem } from "@/pages/settings/reviewer/ConfigItem"
import { ExcludeConfigManager } from "@/pages/settings/reviewer/ExcludeConfigManager"
import { useFetchRepositories } from "@/hooks/queries/useFetchRepositories"
import { useUpdateReviewerExcludedConfiguration } from "@/hooks/mutations/useUpdateReviewerExcludedConfiguration"
import { useDeleteReviewerExcludedConfiguration } from "@/hooks/mutations/useDeleteReviewerExcludedConfiguration"
import { useFetchAcceptanceRates } from "@/hooks/queries/useFetchAcceptanceRates"
import { CircularGraph } from "@/components/ui/CircularGraph.tsx"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components"
import { aggregateAcceptanceData } from "@/pages/settings/reviewer/utils.ts"

export function Preferences() {
  const { data: backendUser, isLoading } = useFetchUser()
  const { data: reviewerConfig, isLoading: isReviewerConfigLoading } = useFetchReviewerConfig()
  const { mutateAsync: updateReviewerConfig } = useUpdateReviewerConfiguration()
  const { data: repositories, isLoading: repositoriesLoading } = useFetchRepositories()
  const { data: acceptanceRates, isLoading: acceptanceRatesLoading } = useFetchAcceptanceRates()
  const { mutateAsync: updateExcludedConfiguration } = useUpdateReviewerExcludedConfiguration()
  const { mutateAsync: deleteExcludedConfiguration } = useDeleteReviewerExcludedConfiguration()

  const { t } = useTranslation("Reviewer")
  document.title = "Settings - Preferences"

  const mergedReviewerTypes = useMemo(() => {
    const findingConfig = reviewerConfig?.findingConfig || []
    return REVIEWER_TYPES.map((reviewerType) => {
      const config = findingConfig.find((config: FindingConfig) => config.findingType === reviewerType.findingType)

      return {
        ...reviewerType,
        enabled: config?.enabled ?? true
      }
    })
  }, [reviewerConfig])

  const computedRepositoriesOptions = useMemo(
    () =>
      repositories?.map((repo) => ({
        name: repo.fullName,
        value: repo.fullName
      })) || [],
    [repositories]
  )

  const handleClick = (findingType: FindingConfig) => {
    const newConfig = {
      findingType: findingType.findingType,
      enabled: !findingType.enabled
    }
    updateReviewerConfig(newConfig)
  }

  const handleConfigChange = (config: ExcludedConfig | null) => {
    config && updateExcludedConfiguration(config)
  }

  const handleConfigDelete = (repositoryName: string) => {
    deleteExcludedConfiguration({ repoName: repositoryName })
  }

  const isAdmin = useMemo(() => {
    return backendUser && (backendUser.is_owner || backendUser.permissions.some((p) => p.role === "admin"))
  }, [backendUser])

  const { acceptanceRate, accepted, orgCounter, addressedOrgCounter } = useMemo(
    () => aggregateAcceptanceData(acceptanceRatesLoading, acceptanceRates),
    [acceptanceRatesLoading, acceptanceRates]
  )

  if (isLoading || isReviewerConfigLoading) {
    return <SpinnerLoader className="py-4" />
  }

  return (
    <section>
      <div className="mb-2 flex justify-between">
        <h1 className="flex self-center text-s font-medium text-primary">{t("ReviewerWorkflow")}</h1>
        {!isAdmin && (
          <div className="flex h-fit flex-row rounded-md border border-border bg-fill px-4 py-1.5  text-s font-normal text-secondary">
            <AlertCircle className="mr-3 flex size-4 self-center" />
            {t("ConfigurationRestriction")}
          </div>
        )}
      </div>
      {!acceptanceRatesLoading && (
        <div className="mb-4 flex w-full rounded-md border border-border p-4">
          <CircularGraph
            size={20}
            fillColor="var(--blue-link)"
            bgColor="var(--graph-bg)"
            strokeWidth={2}
            total={orgCounter}
            current={addressedOrgCounter}
            className="mx-2"
          />

          <p className="text-s font-medium text-primary">
            {acceptanceRate}% {t("OverallAccepted")}, {accepted} {t("AcceptedSuggestions")}
          </p>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger className="cursor-default">
                <Info className="mx-2 size-5 cursor-default" />
              </TooltipTrigger>
              <TooltipContent>{t("AcceptedExplanation")}</TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      )}

      <div className="mb-4 flex flex-wrap gap-4">
        {mergedReviewerTypes.map((item, index) => {
          const acceptanceStats = acceptanceRatesLoading
            ? undefined
            : acceptanceRates?.acceptanceRates[item.findingType]
          return (
            <ConfigItem item={item} key={index} disabled={!isAdmin} handleClick={handleClick} stats={acceptanceStats} />
          )
        })}
      </div>

      <div>
        {repositoriesLoading ? (
          <SpinnerLoader className="py-4" />
        ) : (
          <ExcludeConfigManager
            repositories={computedRepositoriesOptions}
            existingConfigs={reviewerConfig?.excludedConfig || []}
            onConfigChange={handleConfigChange}
            onConfigDelete={handleConfigDelete}
            disabled={!isAdmin}
          />
        )}
      </div>
    </section>
  )
}
