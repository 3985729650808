import { useTranslation } from "react-i18next"

const ExternalLink = ({ href, children }: { href: string; children: React.ReactNode }) => {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
      {children}
    </a>
  )
}

export const Footer = () => {
  const { t } = useTranslation("ConnectSlack")
  const { t: tAuth } = useTranslation("Auth")
  return (
    <div className="mt-8 flex flex-col justify-center gap-2 border-t-2 p-2 text-xs text-primary">
      <p>{t("BazUtilizes")}</p>
      <p>{t("BazPermissions")}</p>
      <p>
        By connecting your Slack account, you agree to our{" "}
        <ExternalLink href="https://baz.co/legal#terms" children={tAuth("TermsOfUse")} /> {tAuth("And")}{" "}
        <ExternalLink href="https://baz.co/legal#privacy" children={tAuth("PrivacyStatement")} />
      </p>
    </div>
  )
}
