import { errorHandler } from "@/services/api/helpers"
import { CommitHistory, EmptyFolder, FC, NoReadme, SkeletonLoader } from "@/components"
import { getFileExtension } from "@/components/designSystem/FCC/utils/codeCellComputations"
import { ContentMetadata } from "@/models/FC"
import { MdTransformer } from "@/lib/mdTransformer"
import { useParams } from "react-router-dom"
import { Themes, useSettings } from "@/services/store/useSettings.ts"

interface Props {
  fileLoading: boolean
  fileError: unknown
  content?: string | object
  contentMetadata?: ContentMetadata
}

interface RendererProps {
  content?: string | object
  contentMetadata?: ContentMetadata
  fileExtension: string
  pathStart?: string
  theme: Themes
}

const ContentRenderer = ({ content, contentMetadata, fileExtension, pathStart, theme }: RendererProps) => {
  if (!contentMetadata) {
    return <div></div>
  }

  if (!content) {
    return <div className="py-6">{pathStart ? <EmptyFolder /> : <NoReadme />}</div>
  }

  if (fileExtension === "md" && typeof content === "string") {
    return (
      <div className="py-6">
        <div className="flex justify-end py-2">
          <CommitHistory commits={contentMetadata.commits} />
        </div>
        <div>{MdTransformer(content, theme)}</div>
      </div>
    )
  }
  return <FC file={content} contentMetadata={contentMetadata} />
}

export const FileContent = ({ fileError, fileLoading, content, contentMetadata }: Props) => {
  const { selectedTheme } = useSettings()
  const fileExtension = getFileExtension(contentMetadata?.file_path)
  const { pathStart } = useParams()

  return (
    <div className="mb-24 w-full overflow-y-auto pl-6 pr-12 text-xs">
      {fileLoading ? (
        <SkeletonLoader className="py-6" />
      ) : (
        <ContentRenderer
          pathStart={pathStart}
          content={content}
          contentMetadata={contentMetadata}
          fileExtension={fileExtension}
          theme={selectedTheme}
        />
      )}
      {fileError ? <p>{errorHandler(fileError)}</p> : null}
    </div>
  )
}
