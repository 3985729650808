import { Comment as CommentI, Discussion } from "@/models/Discussions"
import { isCommentSuggestion } from "../../utils"
import { Suggestion } from "../SuggestionV2"
import { Comment } from "../Comment"

interface CommentRendererProps {
  comment: CommentI
  index: number
  discussion: Discussion
  handleResolveToggle: (resolvedState: boolean) => void
  isResolved: boolean
}

export const CommentRenderer = ({
  comment,
  index,
  discussion,
  handleResolveToggle,
  isResolved
}: CommentRendererProps) => {
  if (isCommentSuggestion(comment)) {
    return (
      <Suggestion
        key={comment.id}
        comment={comment}
        current={discussion}
        handleResolveToggle={handleResolveToggle}
        isResolved={isResolved}
        commentIndex={index}
      />
    )
  }

  return (
    <Comment
      key={comment.id}
      comment={comment}
      current={discussion}
      handleResolveToggle={handleResolveToggle}
      isResolved={isResolved}
      commentIndex={index}
    />
  )
}
