import { SpinnerLoader } from "@/components/designSystem/Loaders/SpinnerLoader"
import { useSlackInstallation } from "@/hooks/queries/useSlackInstallation"
import { routes } from "@/lib/routes.ts"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"

export function SlackCallback() {
  const [searchParams] = useSearchParams()
  const code = searchParams.get("code")
  const state = searchParams.get("state")
  const { t } = useTranslation("Integrations")
  const navigate = useNavigate()

  const { error, isLoading, data } = useSlackInstallation({ code, state })

  useEffect(() => {
    if (data) {
      navigate({ pathname: routes.settings.integrations({ provider: "slack" }), search: "" })
    }
  }, [data, navigate])

  if (error) {
    return <div className="flex w-full justify-center">{t("IntegrationError")}</div>
  }

  return <div className="flex w-full justify-center">{isLoading ? <SpinnerLoader /> : null}</div>
}
