import { useQuery } from "@tanstack/react-query"
import { axiosClient } from "@/services/api/axiosClient"
import { apiRoutes } from "@/services/api/api-routes"
import { IntegrationType, OAuthState } from "@/models/Integration.ts"

export const useFetchOAuthState = (integrationType: IntegrationType) => {
  const { error, isLoading, data } = useQuery<OAuthState, unknown>({
    queryKey: ["oauth-state", integrationType],
    queryFn: async () => {
      const response = await axiosClient.get(apiRoutes.integrations.oauthState.get(integrationType))
      return response.data
    }
  })

  return { error, isLoading, data }
}
