import { CRContentSection } from "./CRContentSection"
import {
  Button,
  InlineDiscussionsLayout,
  SkeletonLoader,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from "@/components"
import { FileWithDiffs } from "@/models/FCC"
import { ChangeRequest } from "@/models/Changes"
import { Discussion } from "@/models/Discussions"
import { useMemo, useState } from "react"
import { Bazzy } from "@/components/designSystem/Discussions/components/Bazzy"
import { useBazzy } from "@/services/store/useBazzy"
import { useTranslation } from "react-i18next"
import { handleScrollToTop } from "@/pages/crFileChanges/utils"
import { DescriptionDialog } from "@/pages/crFileChanges/components/DescriptionDialog"
import { ArrowUp } from "lucide-react"
import { AnimatePresence, motion } from "framer-motion"
import AnimatedSparkles from "@/assets/icons/AnimatedSparkles"

interface Props {
  changeRequestData?: ChangeRequest
  isAuthor: boolean
  discussionsData?: Discussion[]
  diffData: FileWithDiffs[]
  diffError: Error | null
  discussionsError: Error | null
  diffLoading: boolean
  discussionsLoading: boolean
  hasReachedTarget: boolean
}

export const CRContentWrapper = ({
  changeRequestData,
  isAuthor,
  diffData,
  diffLoading,
  discussionsLoading,
  discussionsData,
  hasReachedTarget
}: Props) => {
  const loadingState = discussionsLoading || !changeRequestData || !discussionsData || diffLoading
  const filePaths = useMemo(() => diffData.map((diff) => diff.file_relative_path), [diffData])
  const { chat, isOpen, toggleBazzy, setEnableFetch } = useBazzy()
  const { t } = useTranslation("Bazzy")
  const { t: ToolbarTranslation } = useTranslation("Toolbar")
  const [shouldShowMainDiscussion, setShouldShowMainDiscussion] = useState(false)

  return (
    <div className="relative flex w-full">
      {!diffLoading && !loadingState ? (
        <div data-id="content-height-wrapper" className="flex w-full">
          <CRContentSection data={diffData} filesViewed={changeRequestData?.files_viewed} />
          <InlineDiscussionsLayout
            discussions={discussionsData}
            changeRequest={changeRequestData}
            filePaths={filePaths}
          />
        </div>
      ) : (
        <SkeletonLoader className="w-full pt-6" />
      )}

      {isOpen && <Bazzy scrollReachedTarget={hasReachedTarget} />}
      {!isOpen && chat.length > 0 && (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger className="relative cursor-default" asChild>
              <Button
                className={`fixed right-[72px] ${hasReachedTarget ? "top-[230px] mt-0" : "mt-10"} z-90 size-6 bg-primary p-0`}
                onClick={() => {
                  setEnableFetch(false)
                  toggleBazzy(true)
                }}
              >
                <AnimatedSparkles className="cursor-pointer text-background" width={14} height={16} />
              </Button>
            </TooltipTrigger>
            <TooltipContent className="max-w-[230px]">{t("ClosedBazzyTooltip")}</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )}

      <AnimatePresence>
        {hasReachedTarget ? (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.4 }}
            className="fixed bottom-5 left-1/2 z-90 flex h-12 w-fit -translate-x-1/2"
          >
            <Button
              onClick={handleScrollToTop}
              variant="outline"
              size="sm"
              className="gap-1 rounded font-inter text-xs shadow-md"
            >
              <ArrowUp width={14} height={14} />
              {ToolbarTranslation("ScrollToTop")}
            </Button>
          </motion.div>
        ) : null}
      </AnimatePresence>

      {changeRequestData?.description && !isAuthor && (
        <DescriptionDialog
          changeRequest={changeRequestData}
          open={shouldShowMainDiscussion}
          onClose={() => setShouldShowMainDiscussion(false)}
        />
      )}
    </div>
  )
}
