import { Avatar } from "@/components"
import { MouseEvent, useCallback, useState } from "react"
import { EditComment } from "./EditComment"
import { useForm } from "react-hook-form"
import { CommentCreationData } from "@/components/designSystem/Discussions/components/Comment/CommentCreationData"
import { RenderComment } from "@/components/designSystem/Discussions/components/Comment/RenderComment"
import { Comment as CommentInterface, Discussion, NewCommentBubble } from "@/models/Discussions"
import { useDiscussions } from "@/services/store/useDiscussions"
import { useToast } from "@/hooks/useToast"
import { useDeleteComment } from "@/hooks/mutations/useDeleteComment"
import { ghWriteErrorHandler } from "@/services/api/helpers"

export interface CommentProps {
  comment: CommentInterface
  current: NewCommentBubble | Discussion | null
  isResolved: boolean
  commentIndex: number
  crId?: string
  isSuggestion?: boolean
  handleResolveToggle?: (resolvedState: boolean) => void
}

export const Comment = ({ comment, current, crId, handleResolveToggle, commentIndex, isResolved }: CommentProps) => {
  const [dropDownOpen, setDropDownOpen] = useState(false)
  const { toast } = useToast()
  const { mutateAsync } = useDeleteComment()
  const [editMode, setEditMode] = useState(false)

  const { toggleManualRecalculationTrigger } = useDiscussions()

  const handleToggleMenu = useCallback(
    (event: MouseEvent<Element> | CustomEvent<{ originalEvent: PointerEvent }>) => {
      event.stopPropagation()

      if (!comment.editable) {
        return
      }
      setDropDownOpen((prev) => !prev)
    },
    [setDropDownOpen, comment.editable]
  )

  const handleDelete = useCallback(
    async (event: MouseEvent<Element>) => {
      event.stopPropagation()
      try {
        await mutateAsync({ commentId: comment.id })
      } catch (err) {
        toast({
          variant: "destructive",
          description: ghWriteErrorHandler(err)
        })
      } finally {
        handleToggleMenu(event)
      }
    },
    [mutateAsync, comment.id, toast, handleToggleMenu]
  )

  const handleEditClick = useCallback(
    (event: MouseEvent<Element>) => {
      handleToggleMenu(event)
      toggleManualRecalculationTrigger()
      setEditMode(!editMode)
    },
    [editMode, setEditMode, handleToggleMenu, toggleManualRecalculationTrigger]
  )

  const form = useForm({
    defaultValues: {
      body: comment.comment_body
    }
  })
  return (
    <div className="mt-4 flex w-full flex-col">
      <div className="mb-2 flex">
        <Avatar
          username={comment.author_user?.display_name || comment.author}
          avatarURL={comment.author_user?.avatar_url}
          className="mr-2 size-6 text-sm"
        />
        <CommentCreationData
          comment={comment}
          dropDownOpen={dropDownOpen}
          handleToggleMenu={handleToggleMenu}
          handleDelete={handleDelete}
          handleEditClick={handleEditClick}
        />
      </div>
      <div>
        <div className="flex w-full flex-col break-words">
          <div className="w-full text-primary">
            {editMode ? (
              <EditComment comment={comment} form={form} setEditMode={setEditMode} />
            ) : (
              <RenderComment
                comment={comment}
                current={current}
                crId={crId}
                handleResolveToggle={handleResolveToggle}
                commentIndex={commentIndex}
                isResolved={isResolved}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
