import { Fragment } from "react"
import { Comment as CommentI, Discussion } from "@/models/Discussions"
import { CommentRenderer } from "./CommentRenderer"
import { ExpandCollapseButton } from "./ExpandCollapseButton"

interface UnresolvedDiscussionViewProps {
  comments: CommentI[]
  discussion: Discussion
  handleResolveToggle: (resolvedState: boolean) => void
  isResolved: boolean
  areAllCommentsExpanded: boolean
  setAreAllCommentsExpanded: (value: boolean) => void
}

export const UnresolvedDiscussionView = ({
  comments,
  discussion,
  handleResolveToggle,
  isResolved,
  areAllCommentsExpanded,
  setAreAllCommentsExpanded
}: UnresolvedDiscussionViewProps) => {
  const shouldShowButton = comments.length >= 3

  const hiddenCount = Math.max(0, comments.length - 3)

  const toggleExpanded = () => setAreAllCommentsExpanded(!areAllCommentsExpanded)

  const shouldCommentBeVisible = (index: number) =>
    index <= 1 || index === comments.length - 1 || areAllCommentsExpanded

  return (
    <>
      {comments.map((comment, index) => (
        <Fragment key={comment.id}>
          {shouldCommentBeVisible(index) && (
            <CommentRenderer
              comment={comment}
              index={index}
              discussion={discussion}
              handleResolveToggle={handleResolveToggle}
              isResolved={isResolved}
            />
          )}

          {index === 1 && shouldShowButton && hiddenCount > 0 && (
            <ExpandCollapseButton
              isExpanded={areAllCommentsExpanded}
              hiddenCount={hiddenCount}
              onClick={toggleExpanded}
            />
          )}
        </Fragment>
      ))}
    </>
  )
}
