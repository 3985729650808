import { useTranslation } from "react-i18next"
import { getChecksWithExtraData, getInsightsData } from "@/components/designSystem/Topics/helpers.ts"
import TopicBadge from "@/components/designSystem/Topics/TopicBadge.tsx"
import { useChangeRequest } from "@/services/store/useChangeRequest.ts"
import { useMemo } from "react"
import { PlusPopover } from "@/components/designSystem/Topics/PlusPopover"
import { Topic } from "@/models/Topics"
import { PAGE_CONTAINER_PADDING } from "@/pages/crFileChanges/utils"

interface Props {
  minimized: boolean
}

const maxWidthToSliceTopics = 1980

function shouldShowPlusButton(minimized: boolean, topics: Topic[], computedTopics: Topic[]): boolean {
  const windowWidth = window.innerWidth

  return !(!minimized || topics.length <= computedTopics.length || windowWidth >= maxWidthToSliceTopics)
}

export const Topics = ({ minimized }: Props) => {
  const { t } = useTranslation("Topics")
  const { topics } = useChangeRequest()

  const computedTopics = useMemo(() => {
    return minimized && window.innerWidth < maxWidthToSliceTopics ? topics.slice(0, 4) : topics
  }, [topics, minimized])

  return (
    <div className={`flex ${minimized ? `${PAGE_CONTAINER_PADDING} pt-1.5` : ""}`}>
      <div className="mr-2 h-8 cursor-default content-center text-s text-secondary">{t("Topics")}</div>

      <div className="mr-2 flex flex-wrap items-center gap-2">
        {[...computedTopics]
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((topic) => (
            <div key={topic.id} className="w-fit break-normal rounded font-inter text-xxs leading-5 text-secondary">
              <TopicBadge
                topic={topic}
                getInsightsData={getInsightsData}
                getChecksWithExtraData={getChecksWithExtraData}
                minimized={minimized}
              />
            </div>
          ))}
      </div>

      {shouldShowPlusButton(minimized, topics, computedTopics) ? (
        <PlusPopover topics={topics} computedTopics={computedTopics} minimized={minimized} />
      ) : null}
    </div>
  )
}
