import Markdown from "react-markdown"
import remarkGfm from "remark-gfm"
import remarkEmoji from "@baz-scm/remark-emoji"
import rehypeRaw from "rehype-raw"

import { Themes } from "@/services/store/useSettings.ts"
import { createMarkdownComponents } from "./markdownComponents"
import type { MdTransformerOptions } from "./types"

const checkHrefWithQuotes = (markdown: string): string => {
  // This captures the href value and any trailing attributes separately
  return markdown.replace(/<a\s+href=([^"'>\s]+)([\s][^>]*)?>/, (url, rest) => {
    const attributes = rest || ""
    return `<a href="${url}"${attributes}>`
  })
}

export const MdTransformer = (markdown: string, theme: Themes, options?: MdTransformerOptions) => {
  const components = createMarkdownComponents(theme, options)
  const processedMarkdown = checkHrefWithQuotes(markdown)

  return (
    <Markdown
      className="markdown h-full"
      remarkPlugins={[remarkGfm, [remarkEmoji, { emoticon: true }]]}
      rehypePlugins={[rehypeRaw]}
      components={components}
    >
      {processedMarkdown}
    </Markdown>
  )
}
