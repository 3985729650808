import { Discussion as DiscussionI, NewCommentBubble } from "@/models/Discussions"
import { useCallback, useEffect, useState } from "react"
import { calculateRowTopPosition } from "../utils"
import { useDiscussions } from "@/services/store/useDiscussions"
import { ExpandedDiscussion } from "./ExpandedDiscussion"
import { DiscussionChip } from "./ChipDiscussion"
import { useSyncElementPosition } from "@/components/designSystem/Discussions/Inline/useSyncElementPosition"
import { HEADER_HEIGHT } from "@/services/store/useChangeRequest"

interface Props {
  current: DiscussionI
  prev?: DiscussionI | NewCommentBubble
  discussionNumber: number
  crId: string
  commitSha: string
  total: number
  discussions: Array<DiscussionI | NewCommentBubble>
  id: string
}

export const Discussion = ({ current, discussionNumber, prev, total, discussions, id, crId, commitSha }: Props) => {
  const [isResolved, setIsResolved] = useState(current.discussion_state === "resolved")
  const {
    setCurrentOpenedDiscussion,
    toggleManualRecalculationTrigger,
    discussionsExpanded,
    updateDiscussionWithTop,
    toggleExpandDiscussionsContainer
  } = useDiscussions()

  const { topPosition } = useSyncElementPosition({
    current,
    discussionNumber,
    discussions,
    total,
    prev
  })

  const handleOpen = useCallback(() => {
    setCurrentOpenedDiscussion(current)
    const top = calculateRowTopPosition(current)
    scrollTo({ top: top - HEADER_HEIGHT, behavior: "smooth" })
    toggleExpandDiscussionsContainer()
    toggleManualRecalculationTrigger()
  }, [setCurrentOpenedDiscussion, current, toggleExpandDiscussionsContainer, toggleManualRecalculationTrigger])

  const handleResolveToggle = useCallback(
    (resolveState: boolean) => {
      setIsResolved(!resolveState)
      toggleManualRecalculationTrigger()
    },
    [toggleManualRecalculationTrigger]
  )

  useEffect(() => {
    toggleManualRecalculationTrigger()
    topPosition && updateDiscussionWithTop(current.id, topPosition)
  }, [topPosition, updateDiscussionWithTop, current, discussions, toggleManualRecalculationTrigger])

  return (
    <>
      {discussionsExpanded ? (
        <ExpandedDiscussion
          current={current}
          discussionNumber={discussionNumber}
          handleOpen={handleOpen}
          isResolved={isResolved}
          handleResolveToggle={handleResolveToggle}
          topPosition={topPosition}
          id={id}
          crId={crId}
          commitSha={commitSha}
        />
      ) : (
        <DiscussionChip
          current={current}
          discussionNumber={discussionNumber}
          isResolved={isResolved}
          handleOpen={handleOpen}
          topPosition={topPosition}
          id={id}
        />
      )}
    </>
  )
}
