interface Props {
  size: number
  fillColor: string
  bgColor: string
  total: number
  current: number
  className?: string
  strokeWidth: number
}

export const CircularGraph = (props: Props) => {
  const { size, fillColor, bgColor, total, current, className, strokeWidth } = props
  const radius = (size - strokeWidth) / 2
  const circumference = 2 * Math.PI * radius
  const progress = (current / total) * circumference
  const halfSize = size / 2

  const commonParams = {
    cx: halfSize,
    cy: halfSize,
    r: radius,
    fill: "none",
    strokeWidth
  }

  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} className={className}>
      <circle {...commonParams} stroke={bgColor} />
      <circle
        {...commonParams}
        stroke={fillColor}
        strokeDasharray={`${progress} ${circumference - progress}`}
        strokeLinecap="round"
      />
    </svg>
  )
}
