import { ForwardedRef, MutableRefObject } from "react"
import { HEADER_HEIGHT } from "@/services/store/useChangeRequest"

/**
 * Standard height of the top navigation bar in pixels
 */
export const topNavigationHeight = 64

/**
 * Checks if a ForwardedRef is a valid MutableRefObject with a current value
 */
export function isProperRef(ref: ForwardedRef<HTMLElement | null>): ref is MutableRefObject<HTMLElement> {
  return !!(typeof ref !== "function" && ref && ref.current)
}

/**
 * Gets the content height from an element ref or falls back to document body
 */
export function getContentHeight(elementRef: ForwardedRef<HTMLElement | null>): number {
  return isProperRef(elementRef) ? elementRef.current.offsetHeight : document.body.offsetHeight
}

/**
 * Calculates the height of the scrollbar thumb proportional to the content
 */
export function calculateScrollbarHeight(elementRef: ForwardedRef<HTMLElement | null>): number {
  // Get the total height of the content
  const contentHeight = getContentHeight(elementRef)

  // Calculate scrollbar height using the formula:
  // (viewport height - top navigation) * (available height / total content height)
  // This creates a proportional scrollbar height based on how much content is visible
  const availableHeight = getAvailableHeight()
  return availableHeight * (availableHeight / contentHeight)
}

/**
 * Calculates the top position of the scrollbar thumb based on scroll position
 */
export function calculateScrollbarTopPosition(elementRef: ForwardedRef<HTMLElement | null>): number {
  // Return the scrollbar position by dividing current scroll position by the ratio
  return window.scrollY / scrollRatio(elementRef)
}

/**
 * Calculates the position of a line marker as a percentage of total scroll height
 */
export function calculateLineMarkTop(elementRef: ForwardedRef<HTMLElement | null>, discussionTop?: number): number {
  // Calculate the line marker position as a percentage of total scroll height
  return isProperRef(elementRef) && discussionTop
    ? Math.floor((discussionTop / elementRef.current.scrollHeight) * 100)
    : 0
}

/**
 * Checks if content is tall enough to need a scrollbar
 */
export function isVisibleScroll(elementRef: ForwardedRef<HTMLElement | null>, scrollbarHeight: number): boolean {
  const contentHeight = getContentHeight(elementRef)
  return scrollbarHeight < contentHeight
}

/**
 * Converts a percentage (0-100) to pixels based on a reference height
 */
export function percentageToPixels(percentage: number, referenceHeight: number): number {
  const decimalPercentage = percentage / 100
  const pixelValue = referenceHeight * decimalPercentage
  return Math.floor(pixelValue)
}

/**
 * Scrolls to the position corresponding to where the scrollbar was clicked
 */
export function navigateToClickedScrollbarLocation(
  positionY: number,
  elementRef: ForwardedRef<HTMLElement | null>
): void {
  // Adjust for navigation bar height
  const clickedPositionY = positionY - topNavigationHeight
  const scrollToTop = scrollRatio(elementRef) * clickedPositionY

  // Scroll to the calculated position, accounting for any sticky elements
  scrollTo({ top: scrollToTop - HEADER_HEIGHT, behavior: "instant" })
}

/**
 * Converts scrollbar thumb position to equivalent window scroll position
 */
export function transformThumbTopToWindowScroll(
  elementRef: ForwardedRef<HTMLElement | null>,
  thumbTop: number
): number {
  return thumbTop * scrollRatio(elementRef)
}

/**
 * Calculates the maximum position the scrollbar thumb can move to
 */
export function calculateScrollbarBottomBoundary(
  contentRef: ForwardedRef<HTMLElement | null>,
  scrollAreaRef: MutableRefObject<HTMLDivElement | null>
): number {
  const thumbHeight = calculateScrollbarHeight(contentRef)
  const scrollAreaHeight = scrollAreaRef.current?.scrollHeight || Infinity
  return scrollAreaHeight - thumbHeight
}

// Calculate available viewport height (minus navigation)
export function getAvailableHeight(): number {
  return window.innerHeight - topNavigationHeight
}

export const scrollRatio = (elementRef: ForwardedRef<HTMLElement | null>) =>
  getContentHeight(elementRef) / getAvailableHeight()
