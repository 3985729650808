import { ChangeRequest } from "@/models/Changes"
import { CRTitle } from "./CRTitle.tsx"
import { ApproveButton } from "@/pages/crFileChanges/components/CRHeader/CRActions/ApproveButton.tsx"
import { usePermissions } from "@/services/store/usePermissions"
import { MergeButton } from "@/pages/crFileChanges/components/CRHeader/CRActions/MergeButton"
import { Topics } from "@/components/designSystem/Topics"
import { CRSubHeader } from "@/pages/crFileChanges/components/CRHeader/CRSubHeader"
import { AnimatePresence } from "framer-motion"
import { ReviewersSection } from "@/pages/crFileChanges/components/CRHeader/ReviewersSection"
import { CENTER_STYLES, PAGE_CONTAINER_PADDING } from "@/pages/crFileChanges/utils"

interface Props {
  changeRequest: ChangeRequest
  isMinimalHeader: boolean
  isOpen: boolean
  onOpen: () => void
  foldersTreeOpen: boolean
}

// Component for the action buttons (Merge, Approve)
const ActionButtons = ({
  changeRequest,
  myCR,
  isMerged
}: {
  changeRequest: ChangeRequest
  myCR: boolean
  isMerged: boolean
}) => {
  if (isMerged) return null

  return (
    <div className="flex items-center gap-2">
      <MergeButton changeRequest={changeRequest} isPrimary={myCR} />
      {!myCR && <ApproveButton changeRequest={changeRequest} />}
    </div>
  )
}

// Component for minimal header content
const MinimalHeaderContent = ({
  changeRequest,
  isOpen,
  onOpen,
  myCR,
  isMerged
}: {
  changeRequest: ChangeRequest
  isOpen: boolean
  onOpen: () => void
  myCR: boolean
  isMerged: boolean
}) => {
  return (
    <>
      <div className={`${CENTER_STYLES} border-b border-border pb-1.5 ${PAGE_CONTAINER_PADDING}`}>
        <CRTitle changeRequest={changeRequest} isMinimalHeader={true} isOpen={isOpen} onOpen={onOpen} />

        <div className={`flex items-center ${!isMerged ? "gap-3" : ""}`}>
          <ReviewersSection changeRequest={changeRequest} />
          <ActionButtons changeRequest={changeRequest} myCR={myCR} isMerged={isMerged} />
        </div>
      </div>
      <Topics minimized={true} />
    </>
  )
}

// Component for standard header content
const StandardHeaderContent = ({
  changeRequest,
  isOpen,
  onOpen,
  myCR,
  isMerged,
  foldersTreeOpen
}: {
  changeRequest: ChangeRequest
  isOpen: boolean
  onOpen: () => void
  myCR: boolean
  isMerged: boolean
  foldersTreeOpen: boolean
}) => {
  return (
    <>
      <div className={`${CENTER_STYLES} ${PAGE_CONTAINER_PADDING}`}>
        <CRTitle changeRequest={changeRequest} isMinimalHeader={false} isOpen={isOpen} onOpen={onOpen} />

        <div className={`flex items-center ${!isMerged ? "gap-3" : ""}`}>
          <ReviewersSection changeRequest={changeRequest} />
          <ActionButtons changeRequest={changeRequest} myCR={myCR} isMerged={isMerged} />
        </div>
      </div>
      <AnimatePresence>
        <CRSubHeader foldersTreeOpen={foldersTreeOpen} changeRequest={changeRequest} isMinimalHeader={false} />
      </AnimatePresence>
    </>
  )
}

export const CRHeader = ({ changeRequest, isMinimalHeader, isOpen, onOpen, foldersTreeOpen }: Props) => {
  const { user } = usePermissions()
  const myCR = user?.github_login === changeRequest.author_name
  const currentState = changeRequest.is_draft && changeRequest.state === "open" ? "draft" : changeRequest.state
  const isMerged = currentState === "merged"

  return (
    <div
      className={`sticky top-16 z-50 ${CENTER_STYLES} flex-col bg-background ${isMinimalHeader ? "border-b py-[9px]" : "border-b-0 py-3"} `}
    >
      {isMinimalHeader ? (
        <MinimalHeaderContent
          changeRequest={changeRequest}
          isOpen={isOpen}
          onOpen={onOpen}
          myCR={myCR}
          isMerged={isMerged}
        />
      ) : (
        <StandardHeaderContent
          changeRequest={changeRequest}
          isOpen={isOpen}
          onOpen={onOpen}
          myCR={myCR}
          isMerged={isMerged}
          foldersTreeOpen={foldersTreeOpen}
        />
      )}
    </div>
  )
}
