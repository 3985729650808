import { AcceptanceRates } from "@/models/ReviewerConfig.ts"
import { formatNumberShort } from "@/lib/utils.ts"

export interface AggregatedAcceptanceData {
  acceptanceRate: number
  accepted: string
  orgCounter: number
  addressedOrgCounter: number
}

export function aggregateAcceptanceData(
  acceptanceRatesLoading: boolean,
  acceptanceRates?: AcceptanceRates
): AggregatedAcceptanceData {
  if (acceptanceRatesLoading || !acceptanceRates)
    return { acceptanceRate: 0, accepted: "0", orgCounter: 0, addressedOrgCounter: 0 }
  const { orgCounter, addressedOrgCounter } = Object.values(acceptanceRates.acceptanceRates).reduce(
    (acc, findingStats) => {
      acc.orgCounter += findingStats.orgCounter
      acc.addressedOrgCounter += findingStats.addressedOrgCounter
      return acc
    },
    { orgCounter: 0, addressedOrgCounter: 0 }
  )

  if (orgCounter === 0) {
    return { acceptanceRate: 0, accepted: "0", orgCounter, addressedOrgCounter }
  }

  const acceptanceRate = Math.floor((addressedOrgCounter / orgCounter) * 100)
  const accepted = formatNumberShort(addressedOrgCounter)
  return { acceptanceRate, accepted, orgCounter, addressedOrgCounter }
}
