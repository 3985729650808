import { MdTransformer } from "@/lib/mdTransformer"
import { useSettings } from "@/services/store/useSettings"
import { useTranslation } from "react-i18next"
import { MessageSquare, SquarePen } from "lucide-react"
import { Badge, Button, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components"
import { useChangeRequest } from "@/services/store/useChangeRequest"
import { EditDescriptionDialog } from "@/components/designSystem/Description/EditDescriptionDialog"
import { useCallback, useState } from "react"
import { PlusIcon } from "@radix-ui/react-icons"
import { parseDescription } from "./utils"

export default function Description({
  markdownDescription,
  commentsLength
}: {
  markdownDescription: string
  commentsLength: number
}) {
  const { t } = useTranslation("FCC")
  const { selectedTheme } = useSettings()
  const { setIsCRCommentsDialogOpen, changeRequest } = useChangeRequest()
  const [isEditDescriptionDialogOpen, setIsEditDescriptionDialogOpen] = useState<boolean>(false)

  const { userDescription, generatedDescription } = parseDescription(markdownDescription)

  const handleDescriptionDialogTriggerClick = useCallback(() => {
    setIsEditDescriptionDialogOpen(!isEditDescriptionDialogOpen)
  }, [isEditDescriptionDialogOpen])

  const handleDescriptionDialogClose = useCallback(() => {
    setIsEditDescriptionDialogOpen(false)
  }, [])

  if (!changeRequest) return null

  const isMergedOrClosedCR = changeRequest.state !== "open" && changeRequest.state !== "draft"
  const hasGeneratedDescription = generatedDescription.trim().length > 0
  const hasUserDescription = userDescription.trim().length > 0

  return (
    <div className="relative my-4 flex h-[250px] w-full items-center justify-between rounded border p-6">
      {/* Generated description */}
      {hasGeneratedDescription && (
        <div className={`h-full ${hasUserDescription ? "w-1/2" : "w-full"}`}>
          <h3 className="mb-6 flex h-8 items-center text-s font-semibold">
            <p>{t("GeneratedDescription")}</p>
          </h3>
          <p className="h-[153px] overflow-scroll pr-4 text-xs leading-6">
            {MdTransformer(generatedDescription, selectedTheme)}
          </p>
        </div>
      )}
      {/* Divider */}
      {hasGeneratedDescription && hasUserDescription && <div className="mx-10 h-[190px] w-px bg-border" />}
      {/* User description */}
      {hasUserDescription && (
        <div className={`h-full ${hasGeneratedDescription ? "w-1/2" : "w-full"}`}>
          <div>
            <h3 className="mb-6 flex h-8 items-center justify-between text-s font-semibold">
              <div className="flex items-center">
                <p>{t("UserDescription")}</p>
                <EditDescriptionDialog
                  title={t("EditDescription")}
                  open={isEditDescriptionDialogOpen && hasUserDescription}
                  onClose={handleDescriptionDialogClose}
                  onTriggerClick={handleDescriptionDialogTriggerClick}
                  userDescription={userDescription}
                >
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger disabled={isMergedOrClosedCR}>
                        <Button
                          variant="outline"
                          className="ml-4 size-8 p-0 text-primary"
                          disabled={isMergedOrClosedCR}
                        >
                          <SquarePen width={16} height={16} />
                        </Button>
                      </TooltipTrigger>
                      {isMergedOrClosedCR && <TooltipContent>{t("DisabledUserDescriptionEditing")}</TooltipContent>}
                    </Tooltip>
                  </TooltipProvider>
                </EditDescriptionDialog>
              </div>
            </h3>
            <p className="h-[153px] overflow-scroll pr-4 text-xs leading-6">
              {MdTransformer(userDescription, selectedTheme)}
            </p>
          </div>
        </div>
      )}
      {/* Comments and edit description buttons for dialogs */}
      <div id="description-dialogs" className="absolute right-6 top-6 ">
        <Badge
          variant="secondary"
          className="cursor-pointer bg-secondary-teal px-2 py-1 text-xs font-medium"
          onClick={() => setIsCRCommentsDialogOpen(true)}
        >
          <MessageSquare size={16} className="mr-1" />
          {commentsLength} {commentsLength === 1 ? t("Comment") : t("Comments")}
        </Badge>
        {/* Add description button when there's no user description but there is a generated one */}
        {hasGeneratedDescription && !hasUserDescription && (
          <EditDescriptionDialog
            title={t("AddDescription")}
            open={isEditDescriptionDialogOpen}
            onClose={handleDescriptionDialogClose}
            onTriggerClick={handleDescriptionDialogTriggerClick}
            userDescription=""
          >
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger disabled={isMergedOrClosedCR}>
                  <Button
                    variant="outline"
                    disabled={isMergedOrClosedCR}
                    className="ml-3 h-8 px-2 py-1 text-xs font-semibold"
                  >
                    <PlusIcon className="mr-1" />
                    <p>{t("AddDescription")}</p>
                  </Button>
                </TooltipTrigger>
                {isMergedOrClosedCR && <TooltipContent>{t("DisabledUserDescriptionEditing")}</TooltipContent>}
              </Tooltip>
            </TooltipProvider>
          </EditDescriptionDialog>
        )}
      </div>
    </div>
  )
}
